import React from 'react';
import {
  Container, Heading, PageURL, HeadingContainer,
} from './styled';
import Body from './Body';

function FAQ() {
  return (
    <Container>
      <HeadingContainer>
        <Heading>FAQ</Heading>
        <PageURL>Home / Pages / <span>FAQ</span></PageURL>
      </HeadingContainer>
      <Body />
    </Container>
  );
}

export default FAQ;
