import styled from 'styled-components';

export const Container = styled.div`
  height: 400px;
  background-color: #F3F0EB;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled.div`
  font-family: SilverSouthSerif;
  font-size: 38px;
  color: #124C56;
  letter-spacing: 0;
  text-align: center;
  line-height: 46px;
`;

export const SubHeading = styled.div`
  font-family: sofia-pro, sans-serif; 
  font-weight: 700;
  font-size: 14px;
  color: ${props => props.color};
  letter-spacing: 1.4px;
  text-align: center;
  line-height: 23px;
`;