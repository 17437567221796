import styled from 'styled-components';

export const Container = styled.div`
  height: 29.44vw;
  position: relative;
  > img {
    z-index: 0;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  > div:nth-child(2) {
    margin-top: 10.38vw;
    z-index: 1;
    position: relative;
    font-family: BigCaslon-Regular;
    font-size: 3.625vw;
    color: #FFFFFF;
    letter-spacing: 0;
    text-align: center;
    line-height: 75px;
    text-shadow: 2px 2px 16px rgba(0,0,0,0.48);
  }
  > div:nth-child(3) {
    cursor: pointer;
    margin: 0 auto;
    margin-top: 1.438vw;
    z-index: 1;
    position: relative;
    background-color: #FE95A2;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 17.56vw;
    height: 4.000vw;
    > div {
      font-family: sofia-pro, sans-serif;
  font-weight: 700;
      font-size: 18px;
      color: #FFFFFF;
      letter-spacing: 0;
      text-align: center;
      line-height: 20px;
    }
    > img {
      width: 1.375vw;
      margin-left: 0.5625vw;
    }
  }
`;