import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 7.375vw;
  padding-left: 11.25vw;
  display: flex;
  padding-bottom: 18.38vw;
`;

export const MenuItem = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 1.000vw;
  letter-spacing: 0;
  line-height: 26px;
  > div {
    width: fit-content;
    color: ${props => props.active ? '#FE95A2' : '#000000'};
    cursor: pointer;
    margin-bottom: 1.250vw;
  }
`;

export const Menu = styled.div`
  margin-right: 7.938vw;
`;

export const SelectedDetails = styled.div`

`;

export const Divider = styled.div`
  background: rgba(0,0,0,0.10);
  height: 1px;
  width: 100%;
`;

export const Question = styled.div`
  display: flex;
  align-items: center;
  padding: 1.625vw 0;
  cursor: pointer;
  > div {
    font-family: sofia-pro, sans-serif;
  font-weight: 700;
    font-size: 1.313vw;
    color: ${props => props.active ? '#FE95A2' : '#333333'};
    letter-spacing: 0;
    line-height: 32px;
  }
  img {
    margin-right: 0.9375vw;
    height: 1.000vw;
    width: 1.000vw;
  }
`;

export const Answer = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 1.000vw;
  color: #666666;
  letter-spacing: 0.1px;
  line-height: 26px;
  margin-left: 1.938vw;
  margin-bottom: 30px;
`;