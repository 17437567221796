import React from 'react';
import {
  Container, Heading, SubHeading, SubmitButton,
} from './styled';

function SupportSection() {
  return (
    <Container>
      <div style={{
        display: 'flex',
        margin: '6.250vw 10.63vw',
        flexDirection: 'column',
      }}>
        <Heading>Can't Find What You're Looking For?</Heading>
        <SubHeading>If you are interested in our services and would like additional information.</SubHeading>
      </div>
      <SubmitButton>Submit a ticket</SubmitButton>
    </Container>
  );
}

export default SupportSection;
