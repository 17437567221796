import React, { Component } from 'react';
import { questions } from '../../../../Mobile/FAQ/Section2';
import {
  Container, Menu, MenuItem, SelectedDetails, Question, Divider, Answer,
} from './styled';
import PlusIcon from './PlusIcon.svg';
import PlusIconActive from './PlusIconActive.svg';

export class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSelection: 0,
      openQuestionIndex: 0,
    };
  }
  render() {
    return (
      <Container>
        <Menu>
          {questions.map(({ title }, index) => (
            <MenuItem
              onClick={() => this.setState({ currentSelection: index })}
              active={index === this.state.currentSelection}
              key={index}>
              <div>{title}</div>
            </MenuItem>
          ))}
        </Menu>
        <SelectedDetails>
          {
            questions[this.state.currentSelection].questions.map(({
              question, answer, component,
            }, index) => (
              <div key={index}>
                <Question
                  onClick={() => this.setState({ openQuestionIndex: index })}
                  active={this.state.openQuestionIndex === index}>
                  {this.state.openQuestionIndex === index ? <img src={PlusIconActive} /> : <img src={PlusIcon} />}
                  <div>{question}</div>
                </Question>
                {this.state.openQuestionIndex === index && <Answer>{answer}</Answer>}
                {this.state.openQuestionIndex === index && component}
                {!component && <Divider />}
              </div>
            ))
          }
        </SelectedDetails>
      </Container>
    );
  }
}

export default Details;
