import styled from 'styled-components';

export const Container = styled.div`
  height: 16.94vw;
  background-color: #FAFBFC;
  display: flex;
  align-items: center;
`;

export const Heading = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 1.750vw;
  color: #333333;
  letter-spacing: 0;
  line-height: 42px;
`;

export const SubHeading = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 19px;
  color: #666666;
  letter-spacing: 0;
  line-height: 29px;
`;

export const SubmitButton = styled.div`
  background-color: #FE95A2;
  width:12.94vw;
  height: 3.000vw;
  border-radius: 0.2500vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat-Medium;
  font-size: 0.8750vw;
  color: #FFFFFF;
  letter-spacing: 1px;
  text-align: center;
  line-height: 14px;
  cursor: pointer;
`;