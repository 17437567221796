import React, { useEffect } from 'react';
import { connect } from 'react-redux';
//components
import Layout from '../components/Layout';
import FAQDesktop from '../components/Desktop/FAQ';
import FAQMobile from '../components/Mobile/FAQ';
//actions
import { setMetaTags } from '../actions/homeActions';
//styles
import './style.css';

function FAQ({ setMetaTags }) {
  useEffect(() => {
    const description = 'Frequently Asked Questions';
    const image = '';
    const url = window.location.href;
    const title = `SoBridely`;

    setMetaTags({
      description, image, url, title,
    });
  }, [setMetaTags]);
  return (
    <Layout
      mobile={<FAQMobile />}
      desktop={<FAQDesktop />} />
  );
}

const mapDispatchToProps = { setMetaTags };

export default connect(null, mapDispatchToProps)(FAQ);