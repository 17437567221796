import React from 'react';
import {
  Container, Heading, SubHeading,
} from './styled';
import Details from './Details';
import SupportSection from './SupportSection';
import CTA from './CTA';

function Body() {
  return (
    <Container>
      <Heading>Frequently Asked Questions</Heading>
      <SubHeading>These are here to help you find information about our platform</SubHeading>
      <Details />
      <SupportSection />
      <CTA />
    </Container>
  );
}

export default Body;
