import styled from 'styled-components';

export const Container = styled.div`
  
`;

export const Title = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 38px;
  color: #124C56;
  letter-spacing: 0;
  text-align: center;
  line-height: 46px;
`;

export const SubTitle = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #6B969D;
  letter-spacing: 0;
  text-align: center;
  line-height: 29px;
`;

export const Body = styled.div`
  padding: 118px 30px;
  display: flex;
  flex-direction: column;
`;

export const MenuItem = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #124C56;
  letter-spacing: 0;
  line-height: 26px;
  > div {
    width: fit-content;
    color: ${props => props.active ? '#7DBEC8' : '#124C56'};
    cursor: pointer;
    margin-bottom: 20px;
  }
`;

export const Menu = styled.div`
  margin-right: 150px;
`;

export const SelectedDetails = styled.div`

`;

export const Divider = styled.div`
  background: rgba(0,0,0,0.10);
  height: 1px;
  width: 100%;
`;

export const Question = styled.div`
  display: flex;
  align-items: center;
  padding: 26px 0;
  cursor: pointer;
  > div {
    font-family: sofia-pro, sans-serif;
  font-weight: 400;
    font-size: 21px;
    letter-spacing: 0;
    line-height: 32px;
    color: ${props => props.active ? '#7DBEC8' : '#124C56'};
  }
  img {
    transition: all 0.2s;
    transform: ${props => props.active ? 'rotate(135deg)' : ''};
    margin-right: 20px;
    height: 16px;
    width: 16px;
  }
`;

export const Answer = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size:16px;
  color: #666666;
  letter-spacing: 0.1px;
  line-height: 26px;
  margin-bottom: 20px;
`;