import React, { useState } from 'react';
import { Divider } from '../../../Desktop/FAQ/Body/Details/styled.js';
import PricingDiagram from '../../../PricingDiagram';
import {
  Container, Title, SubTitle, Body, Answer,
  Menu,MenuItem, SelectedDetails, Question,
} from './styled.js';
import PlusIcon from './PlusIcon.svg';

export const questions = [
  {
    title: 'Pricing',
    questions: [
      {
        question: 'How do I know what to price my dress at?', answer:'Its easy! Check out our dress pricing diagram:', component: <PricingDiagram />,
      },
    ],
  },
  {
    title: 'General',
    questions: [
      { question: 'What can I sell on So Bridely?', answer:'So Bridely is an e-commerce site for wedding dresses, new or slightly loved.' },
      { question: 'I don’t know anything about wedding dresses, help!?', answer:'Don’t know the difference between a ball gown and mermaid? Can’t tell if ivory is whiter than off white? Read this blog post and you’ll be a wedding dress expert in no time! ' },
      { question: 'How do I contact a buyer/seller?', answer:'So Bridely offers secure buyer/seller communication through the chat feature available in every listing. We suggest communication only be done through this secure chat, to better ensure protection of both parties. ' },
      { question: 'I am located outside of the U.S. Can I buy/sell on So Bridely?', answer:'At this time, So Bridely is only available within the United States. We are hoping to expand globally in the future!' },
      { question: 'Can I flag a listing or seller/buyer?', answer:'To report a listing that violates the So Bridely guidelines and/or policies at any time, please email help@sobridely.com ' },
    ],
  },
  {
    title: 'Returns',
    questions: [
      { question: 'What if I want to return my dress?', answer:'If you purchased the dress insurance, you have two days (48hrs) after delivery to submit a return form and create a shipping label to send the dress back to the seller. For more information on returns, click here. ' },
      { question: 'Can I try the dress on before I buy it?', answer:'Of course you can with our buyer protection insurance! For $100, try before you buy!' },
    ],
  },
  {
    title: 'Payment',
    questions: [
      { question: 'Which payment methods are accepted?', answer:'All major credit cards are accepted.' },
      { question: 'When do I get paid?', answer:'We do not release payment to the seller until the buyer has received the dress as described. Unless the buyer has purchased the Dress Insurance funds are released 48 hours after the dress has been received. ' },
      { question: 'Buyer/Seller protection?', answer:'You are fully protected when you make a purchase on So Bridely. We will give you a full refund if your gown never ships or does not match the listing description. ' },
    ],
  },
  {
    title: 'For Buyers',
    questions: [
      { question: 'How do I know my size in wedding dresses?', answer:'Dress sizes are different for each designer and usually run smaller than street clothes. The best way to know how well you’ll fit into a gown is to get your height, bust, waist, and hip measurements professionally taken. (link video on how to measure)' },
      { question: 'Can you notify me when a certain dress or designer gets listed?', answer:'Because dresses are being bought and sold so frequently, inventory is constantly changing. We recommend checking the site frequently to see if the dress you’re looking for has been posted. ' },
      { question: 'Why are the prices so low? Are these wedding dresses authentic?', answer:'Dresses sold on So Bridely are either listed by brides who are looking to earn back some of their wedding costs or boutiques making room for new sample gowns. For you this means greatly reduced prices on high quality designer wedding dresses!' },
    ],
  },
  {
    title: 'For Sellers',
    questions: [
      { question: 'How do I know what to price my dress at?', answer:'It’s easy! Check out our dress pricing diagram: ' },
      { question: 'Do I have to pay commission?', answer:'We don’t take commission, what you sell is what you get!' },
      { question: 'Can my listing expire?', answer:'Listings on So Bridely do not expire. Your dress will be posted until it is sold or until you decide to remove the listing. ' },
      { question: 'What is a feed booster?', answer:'A feed booster brings your item to the top of the feed (one time per boost), so more buyers will see your listing! The feed booster button is located on your seller dashboard.' },
      { question: 'How can I get my dress featured on So Bridely’s social media?', answer:'If you are interested in having your dress featured, you can email our marketing team at info@sobridely.com for inquiries. ' },
      { question: 'Should I dry clean my dress before listing it to sell ? ', answer:'Although dresses purchased on So Bridely are done so at the condition listed by the seller, we do recommend having your dress dry cleaned to better ensure quality.' },
      { question: 'How do I remove my listing?', answer:'Its simple! If you no longer need your listing, hit “edit” and select the “remove listing” button. ' },
      { question: 'Which photos of my dress should I post?', answer:'Include photos which capture your dress at all its best angles: front, back and sides! Buyers want to see how the dress will fit so please include a picture of you wearing it on your big day! Make sure your pictures are clear and show what made it so special! ' },
      { question: 'Which package is best for me?', answer:'Our most popular package is our Beautiful package! This will give you the most features for the best price.' },
    ],
  },
  {
    title: 'Shipping',
    questions: [
      { question: 'Who pays for shipping?', answer:'The buyer pays for shipping in addition to the price of the dress unless otherwise specified by the seller. ' },
      { question: 'How should I package my wedding dress?', answer:'To watch a video on how to safely ship your wedding gown please click here. ' },
    ],
  },
];

function Section2() {
  const [currentSelection, setCurrentSelection] = useState(0);
  const [openQuestionIndex, setOpenQuestionIndex] = useState(null);
  return (
    <Container>
      <Title style={{ marginTop: 100 }}>Frequently Asked Questions</Title>
      <SubTitle style={{ margin: 30 }}>These are here to help you find information about our platform</SubTitle>
      <Body>
        <Menu>
          {questions.map(({ title }, index) => (
            <MenuItem
              onClick={() => setCurrentSelection(index)}
              active={index === currentSelection}
              key={index}>
              <div>{title}</div>
            </MenuItem>
          ))}
        </Menu>
        <SelectedDetails>
          {
            questions[currentSelection].questions.map(({
              question, answer, component,
            }, index) => (
              <div key={index}>
                <Question
                  onClick={() => setOpenQuestionIndex(index)}
                  active={openQuestionIndex === index}>
                  <img src={PlusIcon} active={openQuestionIndex === index} />
                  <div>{question}</div>
                </Question>
                {openQuestionIndex === index && <Answer>{answer}</Answer>}
                {openQuestionIndex === index && component}
                {!component && <Divider />}
              </div>
            ))
          }
        </SelectedDetails>
      </Body>
    </Container>
  );
}

export default Section2;