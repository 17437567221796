import React from 'react';
import Button from '../../../Button1';
import Column from '../../../Column';
import {
  Container, Heading, SubHeading,
} from './styled.js';

function Section3() {
  return (
    <Container>
      <Column>
        <Heading>Can't Find What You're Looking For?</Heading>
        <SubHeading>If you are interested in our services and would like additional information.</SubHeading>
      </Column>
      <Button style={{
        marginTop: 20, width: 'auto', padding: '0 10px',
      }}>SUBMIT A TICKET</Button>
    </Container>
  );
}

export default Section3;