import styled from 'styled-components';

export const Container = styled.div`
  height: 271px;
  background: #F3F0EB;
  padding: 30px;
`;

export const Heading = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 28px;
  color: #124C56;
  letter-spacing: 0;
  line-height: 42px;
`;

export const SubHeading = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 19px;
  color: #6B969D;
  letter-spacing: 0;
  line-height: 29px;
  margin-top: 20px
`;