import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BoxStyle = styled.div`
  height: auto;
  padding: 10px;
  background-color: ${props => props.active ? '#6B969D' : '#93b4ae'};
  color: white;
  text-align: center;
  font-family: sofia-pro, sans-serif;
  font-weight: bold;
  cursor: pointer;
`;