import styled from 'styled-components';

export const Container = styled.div`
  background: #F3F0EB;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Heading = styled.div`
  font-family: SilverSouthSerif;
  font-size: 2.375vw;
  color: #333333;
  letter-spacing: 0;
  text-align: center;
  line-height: 46px;
`;

export const PageURL = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 0.8750vw;
  color: #999999;
  letter-spacing: 1.4px;
  text-align: center;
  line-height: 23px;
  span {
    color: #000000;
  }
`;

export const HeadingContainer = styled.div`
  height: 25.00vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;