import React from 'react';
import Row from '../../../Row';
import {
  Container, Title, SubHeading,
} from './styled.js';

function Section1() {
  return (
    <Container>
      <Title>FAQ</Title>
      <Row style={{ marginTop: 16 }}>
        <SubHeading color="#6B969D">HOME&nbsp;/&nbsp;</SubHeading>
        <SubHeading color="#6B969D">HELP&nbsp;/&nbsp;</SubHeading>
        <SubHeading color="#124C56">FAQ</SubHeading>
      </Row>
    </Container>
  );
}

export default Section1;