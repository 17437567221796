import React from 'react';
import CTA from '../CTA';
import { Container } from './styled.js';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';

function FAQMobile() {
  return (
    <Container>
      <Section1 />
      <Section2 />
      <Section3 />
      <CTA />
    </Container>
  );
}

export default FAQMobile;